import Card from 'react-bootstrap/Card';
import headshotPlaceholder from '../placeholderheadshot.png';

export default function CoachCard(props) {
  const width = '320px'
  const picHeight = '475px';
  const fullName = (coach) => { return `${coach.firstName} ${coach.middleName} ${coach.lastName}`};
  return (
    <Card className='bg-3' bg='light' style={{minWidth: width, maxWidth: width}} >
      <Card.Img variant="top" src={props.coach.imgUrl || headshotPlaceholder} style={{width: width, height: picHeight}} />
      <Card.Body>
        <Card.Title>{props.coach.commonName}</Card.Title>
        <Card.Subtitle>{fullName(props.coach)}</Card.Subtitle>
        <Card.Text>
          {`${props.coach.birthdate} - ${props.coach.deathdate}`}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
