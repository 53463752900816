import React, {useState, useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import Search from './Search';
// import CoachCard from './CoachCard';
// import CardDeck from 'react-bootstrap/CardDeck';
// import { Link } from "react-router-dom";
import api from '../lib/api';


const Home = (props) => {
  const [allCoaches, setAllCoaches] = useState([]);

  useEffect( () => {
      const fetchData = async () => {
        if (!allCoaches || allCoaches.length === 0) {
          const data = await api.getCoaches();
          setAllCoaches(data);
        }
      }
      fetchData();
  }, [allCoaches]);


  return (
    <Container>
      {
      (allCoaches && <Search coaches={allCoaches}/>) || <Spinner animation="border" role="status" variant="secondary"/>
      }
    </Container>
  );
}

export default Home;
