const SERVER_URL = process.env.NODE_ENV === 'production' ? 'https://api.thecoachingforest.com': 'http://athena:3000/dev'

class APIClient {
  constructor(server_url) {
    this._url = server_url;
  }

  async request(path) {
    const url = `${this._url}${path}`;
    console.log(`Requesting data from: ${url}`);
    const response = await fetch(url);
    const data = await response.json();
    return data;
  }

  getCoaches() {
    const coaches = this.request('/coaches')
      .then( c => {return c;} );
    return coaches;
  }

  getCoach(coachId) {
    const id = Number(coachId);
    const coach = this.request(`/coaches/${id}`).then( c => c );
    return coach ;
  }

  getTree(coachId) {
    const id = Number(coachId);
    const tree = this.request(`/coaches/${id}/tree`);
    return tree;
  }
}


const api = new APIClient(SERVER_URL);
// Object.freeze(api);

// https://www.smashingmagazine.com/2020/07/custom-react-hook-fetch-cache-data/
// const useApi = (path) => {
//   const cache = {};
//   const [status, setStatus] = useState('idle');
//   const [data, setData] = useState([]);
//   useEffect(() => {
//     console.log('hi');
//     const fetchData = async ()  => {
//       setStatus('fetching');
//       let data;
//       if (cache[path]) {
//         data = cache[path]; 
//       } else{
//         data = await api.request(path);
//         cache[path] = data;
//       }
//       setData(data);
//       setStatus('fetched');
//     }
//     fetchData();
//   }, [path]);
//   return {status, data};
// };
// export {
//   useApi
// };

export default api;
