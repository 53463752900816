import 'bootstrap/dist/css/bootstrap.min.css';
import React from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Container from 'react-bootstrap/Container';

import Coach from './components/Coach.js'
import Teams from './components/Teams';
import Home from './components/Home';

function App() {
  return (
    <Router>
      <Container>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/coaches">
            <Redirect to="/" />
          </Route>
          <Route path={`/coaches/:coachId`}>
            <Coach />
          </Route>
          <Route exact path="/teams">
            <Teams />
          </Route>
        </Switch>
      </Container>
    </Router>
  );
}

export default App;
