import React, {useState, useEffect} from 'react';
import {
  useParams,
} from "react-router-dom";

import api from '../lib/api';
import {ForceGraph2D} from 'react-force-graph';

// const graphData = require('../graphData.json');

export default function Coach(props) {
  const [graphData, setGraphData] = useState({links: [], nodes: []});
  const [coach, setCoach] = useState(null);
  let { coachId } = useParams();
  useEffect( () => {
    if (!coach) {
      api.getTree(coachId).then( (data) => {
          let nodes = [];
          let links = [];
          nodes.push(data.coach);
          const sources = {}
          for (let i = 0; i < data.descendents.length; i++) {
            let neighbor = data.descendents[i];
            if (sources[neighbor.cid]) {
              sources[neighbor.cid].value += 1;
            } else {
              nodes.push(neighbor);
              sources[neighbor.cid] = {'source': neighbor.cid, 'target': data.coach.cid, 'value': 1};
            }
          }
          for (var link in sources) {
            links.push(sources[link]);
          }
        setGraphData({nodes, links});
        setCoach(data.coach);
      })
    }
  });

  const canvasWidth = 500;
  const canvasHeight = 400;
  return (
    <div>
      {coach && <h2>{coach.commonName}</h2>}
      <ForceGraph2D
        nodeId='cid'
        nodeLabel='commonName'
        linkWidth='value'
        graphData={graphData}
        height={canvasHeight}
        width={canvasWidth}
      />
    </div>
  );
}
