import React, {useState} from "react";
import { Typeahead } from 'react-bootstrap-typeahead';
import CoachCard from './CoachCard';
import CardDeck from 'react-bootstrap/CardDeck';
import { Link } from "react-router-dom";

const Search = (props) => {
  const [selected, setSelected] = useState([]);
  return (
    <Typeahead
      id="searchbox"
      options={props.coaches}
      onChange={ (event) => setSelected(event) } 
      labelKey='commonName' 
      filterBy={['commonName', 'firstName', 'lastName']} 
      selected={selected}
      clearButton={true}
    >
      {(state) => {
        let dataSet = [];
        if (state.selected.length > 0) {
          dataSet = state.selected;
        } else if (state.results.length > 0) {
          dataSet = state.results; 
        } else if (!state.text || state.text === '') {
          dataSet = props.coaches;
        }
        return (
          <CardDeck>
            {
              dataSet.map( (c, idx) => {
                return (
                  <Link key={idx} to={`/coaches/${c.cid}`} >
                    <CoachCard className='mx-md-n5' key={idx} coach={c} />
                  </Link>
                );
                })
            }
          </CardDeck>
        );
        }
      }
    </Typeahead>
    );
};

export default Search;
